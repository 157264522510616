import React from "react";
import { Link } from "react-router-dom";
import PPRLanding from "../../assets/img/brand/ppr-landing.png";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section section-lg">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="row-grid justify-content-between align-items-center">
              <Col lg="6">
                <h3 className="display-3">
                  Get in touch with us{" "}
                  {/*<span className="">completed with examples</span>*/}
                </h3>
                <p className="lead">
                  To see our FAQ or get in touch with our support team,
                  you can reach out via the link below.
                  Or send us an email at support[at]powerplayrewards[dot]com
                </p>
                <div className="btn-wrapper">
                  <Button color="default" to="" tag={Link} onClick={(e) => {
                            e.preventDefault();
                            window.open('https://powerplayrewards.freshdesk.com/support/home', "_blank")
                            }}>
                   FAQ
                  </Button>
                  <Button
                    to=""
                    tag={Link} onClick={(e) => {
                      e.preventDefault();
                      window.open('https://app.termly.io/document/terms-and-conditions/31416ab6-380b-4c24-b824-6aa0c9548fae', "_blank")
                      }}>
                  Terms & Conditions
                  </Button>
                  <Button
                    color="primary"
                    to=""
                    tag={Link} onClick={(e) => {
                      e.preventDefault();
                      window.open('https://app.termly.io/document/privacy-policy/e8e5a478-1a18-4c87-9a50-6d9f9f84d033', "_blank")
                      }}>
                  Privacy Policy
                  </Button>
                </div>
              </Col>
              <Col className="mb-lg-auto" lg="5">
                <div className="transform-perspective-right">
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Download Now</small>
                      </div>
                      <img src={PPRLanding} style={{height: "600px"}}/>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>

        </section>
      </>
    );
  }
}

export default Login;
