import React from "react";

// reactstrap components
import { Button, Container, Row, Col,   
  Badge,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup } from "reactstrap";
import PPR from '../../assets/img/brand/ppr.png';
import AppStore from '../../assets/img/icons/app-store.png';
import GooglePlay from '../../assets/img/icons/google-play.png';
import Discover from '../../assets/img/icons/common/discover.png';
import Play from '../../assets/img/icons/common/play.png';
import Earn from '../../assets/img/icons/common/earn.png';

class Hero extends React.Component {
  render() {
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={PPR}
                      style={{}}
                    />
                    <p className="lead text-white">
                      Start playing. Get rewarded!
                    </p>
                    <div className="btn-wrapper mt-5">
                      <a
                        className="btn-icon mb-3 mb-sm-0"
                        color="default"
                        href=""
                        size="lg">
                        <img
                      alt="..."
                      className="img-fluid"
                      src={AppStore}
                      style={{ width: "150px", height : "50px"  }}
                    />
                      </a>{" "}
                     <a
                        className="btn-icon mb-3 mb-sm-0"
                        color="default"
                        href="https://play.google.com/store/apps/details?id=com.powerplayrewardsapp"
                        size="lg">
                        <img
                      alt="..."
                      className="img-fluid"
                      src={GooglePlay}
                      style={{ width: "150px", height : "50px" }}
                    />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
        <section>
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-3">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="fa fa-search" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Discover
                          </h6>
                          <p className="description">
                            Pick a TOP TIER GAME out of our vast library, exclusively provided for you.
                          </p>
                          <img
                                alt="..."
                                className="img-fluid"
                                src={Discover}
                                style={{}}
                              />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-3">
                          <div className="icon icon-shape rounded-circle mb-4" style={{ background: '#d3077863', color: '#D30778' }}>
                            <i className="fa fa-gamepad" />
                          </div>
                          <h6 className="text-uppercase" style={{ color: '#D30778' }}>
                            PLAY
                          </h6>
                          <p className="description">
                            Embark on a quest, complete tasks and COLLECT GOLD.
                          </p>
                          <img
                                alt="..."
                                className="img-fluid"
                                src={Play}
                                style={{}}
                              />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-3">
                          <div className="icon icon-shape rounded-circle mb-4" style={{color: '#c3962e', background: '#ffd37082'}}>
                            <i className="fa fa-money" />
                          </div>
                          <h6 className="text-uppercase" style={{color: '#c3962e'}}>
                            EARN
                          </h6>
                          <p className="description">
                            Spend your earned gold on REAL REWARDS.
                          </p>
                          <img
                                alt="..."
                                className="img-fluid"
                                src={Earn}
                                style={{}}
                              />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
      </>
    );
  }
}

export default Hero;
